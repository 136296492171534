<template>
  <div class="space-y-4">
    <div class="block_leave">
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-alert
            class="md:col-span-2"
            v-if="error"
            variant="error"
            :error-message="error"
          />
          <asom-form-field
            label="Date Range"
            required
            error="Select Date Range"
            :state="inputStates('formData.dateRange')"
          >
            <asom-input-date-range
              :min-date="new Date()"
              :state="inputStates('formData.dateRange')"
              v-model="formData.dateRange"
            />
          </asom-form-field>
          <div></div>
          <asom-form-field
            required
            label="Remarks"
            :state="inputStates('formData.remarks')"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.remarks"
              :state="inputStates('formData.remarks')"
              :maxlength="1000"
            />
          </asom-form-field>
        </form>
        <template #footer>
          <asom-button
            text="Cancel"
            variant="secondary"
            @click="$router.push({ name: 'Supervisor Roster' })"
          />
          <asom-button
            text="Submit"
            @click="submitClicked"
            :loading="isSubmitting"
          />
        </template>
      </asom-card>
    </div>
    <asom-modal
      title="Confirm Indicate Leave"
      v-model="showModal"
      :dismissible="false"
    >
      <p>Are you sure?</p>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <asom-button
          text="Confirm"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          @click="onSubmit"
          class="sm:ml-3"
        />
        <asom-button
          text="Cancel"
          variant="secondary"
          @click="onCloseModal"
        />
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { blockLeave } from "../../services/manpower.service";
import { parseDateTime } from "@/helpers/dateTimeHelpers";
import { minDate } from "@/helpers/customValidations";

export default {
  name: "BlockLeave",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    const date = new Date();
    return {
      formData: {
        dateRange: {
          start: parseDateTime(get(this.$route, "query.startDate", date), false),
          end: parseDateTime(get(this.$route, "query.endDate", date), false),
        },
        remarks: null,
      },
      isLoading: false,
      isSubmitting: false,
      error: null,
      showModal: false,
    };
  },
  validations() {
    return {
      formData: {
        dateRange: {
          start: {
            required,
            minValue: minDate(new Date()),
          },
          end: {
            required,
          },
        },
        remarks: { required },
      },
    };
  },
  mounted() {
    this.v$ && this.v$.$reset();
  },
  methods: {
    submitClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.showModal = true;
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const result = await blockLeave({
        officerId: this.$store.state.auth.userId,
        dateFrom: parseDateTime(this.formData.dateRange.start),
        dateTo: parseDateTime(this.formData.dateRange.end),
        remarks: this.formData.remarks,
      });
      this.isSubmitting = false;
      if (result.success) this.$router.push({ name: "Supervisor Roster" });
      else {
        this.error = result.payload;
        this.$scrollTop();
      }
      this.showModal = false;
    },
    onCloseModal() {
      this.showModal = false;
    },
  },
};
</script>
<style scope>
.block_leave .AsomCard__Body {
  @apply sm:rounded-tr-md sm:rounded-tl-md;
}
</style>
